
import { Vue, Component } from 'vue-property-decorator'
import { SupplierDataList } from '@/types/material'

@Component({
  name: 'SuppliermanageDetail'
})
export default class extends Vue {
  private formInfo: SupplierDataList = {
    supplierId: '',
    supplierName: '',
    supplierCode: '',
    supplierType: '',
    // 经营范围
    operationScope: '',
    // 供应商介绍
    supplierDesc: '',
    // 供应商级别
    supplierLevel: '',
    // 供应商邮编
    supplierPostal: '',
    // 省
    province: '',
    city: '',
    area: '',
    // 详细地址
    address: '',
    contactList: []
  }

  private loading = false

  get supplierId () {
    return this.$route.params.supplierId || ''
  }

  created () {
    this.supplierId && this.getDetail()
  }

  getDetail () {
    this.loading = true
    this.$axios.get(this.$apis.material.selectErpSupplierBySupplierId, {
      supplierId: this.supplierId
    }).then((res) => {
      this.formInfo = res || {}
    }).finally(() => {
      this.loading = false
    })
  }
}
